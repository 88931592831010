<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">物流公司</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>

      <!-- 筛选部分 -->
      <div class="screen">
        <div class="screen_tit">
          <div class="screen_left">
            <i class="el-icon-search"></i>
            筛选查询
          </div>
          <div class="screen_right">
            <el-button @click="screen()" size="mini">查询结果</el-button>
          </div>
        </div>
        <div class="screen_cont">
          <el-form size="small" :inline="true" class="demo-form-inline">
            <el-form-item label="选择商户：" v-show="merchantShow">
              <el-select
                v-model="business_id"
                placeholder="请选择商户"
                @change="changeMerchant(business_id)"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.business_id"
                  :label="item.business_name"
                  :value="item.business_id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="选择门店：" v-show="storeShow">
              <el-select v-model="storeid" placeholder="请选择门店">
                <el-option
                  v-for="item in storeList"
                  :key="item.storeid"
                  :label="item.s_name"
                  :value="item.storeid"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="物流公司：">
              <el-input
                v-model="company_name"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>

            <el-form-item label="启用状态：" prop="name">
              <el-select
                v-model="advert_position"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option
                  v-for="item in advert_position_list"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 添加时的模态框 -->
      <div class="popContainer" v-show="changeShow">
        <transition name="el-fade-in">
          <div class="transition-box">
            <div class="act_tit">
              添加物流公司
              <el-button
                style="float: right; padding: 3px 10px"
                type="text"
                @click="changeShow = false"
                >关闭</el-button
              >
            </div>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              label-width="100px"
              :rules="ruleValidate"
            >
              <el-form-item
                label="适用商户："
                prop="business_id"
                v-show="merchantShow"
              >
                <el-select
                  v-model="ruleForm.business_id"
                  placeholder="请选择商户"
                  @change="changeMerchant(ruleForm.business_id)"
                  style="width: 300px"
                >
                  <el-option
                    v-for="item in ruleForm.businessList"
                    :key="item.business_id"
                    :label="item.business_name"
                    :value="item.business_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="适用门店："
                prop="store_name"
                v-show="storeShow"
              >
                <el-select
                  v-model="ruleForm.storeid"
                  placeholder="请选择门店"
                  style="width: 300px"
                >
                  <el-option
                    v-for="item in ruleForm.storeList"
                    :key="item.s_name"
                    :label="item.s_name"
                    :value="item.storeid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="公司名称：" prop="company_name">
                <el-input
                  v-model="ruleForm.company_name"
                  style="width: 300px"
                ></el-input>
              </el-form-item>

              <el-form-item label="配送描述：" prop="send_describe">
                <el-input
                  type="textarea"
                  v-model="ruleForm.send_describe"
                  style="width: 300px"
                ></el-input>
              </el-form-item>

              <el-form-item label="是否启用：" prop="is_open">
                <el-radio-group v-model="ruleForm.is_open">
                  <el-radio label="0">上线</el-radio>
                  <el-radio label="1">下线</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item size="large">
                <el-button
                  @click="submitForm('ruleForm')"
                  type="primary"
                  style="width: 120px"
                  >提交</el-button
                >
                <el-button @click="changeShow = false">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </transition>
      </div>

      <!-- 表格头部 -->
      <div class="table_head">
        <div class="table_head_left">
          <i class="el-icon-s-unfold"></i>
          数据列表
        </div>
        <div class="table_head_right">
          <ul>
            <li>
              <el-button size="mini" @click="add"> 添加 </el-button>
            </li>
          </ul>
        </div>
      </div>

      <!-- 表格渲染 -->
      <div class="table" v-show="allData">
        <!-- 设置表格高度  height="500" -->
        <el-table
          :data="tableData"
          border
          style="width: 85%; margin-left: 200px; text-align: center"
        >
          <el-table-column prop="logistics_id" label="编号" align="center">
          </el-table-column>

          <el-table-column prop="company_name" label="公司名称" align="center">
          </el-table-column>

          <el-table-column prop="send_describe" label="配送描述" align="center">
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="添加时间"
            align="center"
            width="300"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.create_time }}</span>
            </template>
          </el-table-column>

          <el-table-column label="是否启用" label-width="120px" align="center">
            <template slot-scope="scope">
              <el-switch
                @change="
                  switchChange(
                    scope.row.is_open,
                    scope.row.logistics_id,
                    scope.row.store_id
                  )
                "
                v-model="scope.row.is_open"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <!-- <span style="cursor: pointer" @click="see()">查看</span> -->
              <span
                style="margin-left: 10px; cursor: pointer"
                v-show="storeShow"
                @click="
                  edit(
                    scope.row.store_id,
                    scope.row.company_name,
                    scope.row.send_describe,
                    scope.row.is_open
                  )
                "
              >
                编辑
              </span>
              <span
                v-show="storeShow"
                style="margin-left: 10px; cursor: pointer"
                @click="deleteAdver(scope.row.logistics_id)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="activeListTotal"
          >
          </el-pagination>
        </div>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";

export default {
  data() {
    return {
      top1: "11-3",
      top2: ["11"],
      changeShow: false, //添加、编辑时弹出的模态框
      allData: false, //渲染数据的展示
      company_name: "", //公司名称
      advert_position: "", //	广告位置
      advert_position_list: [], //广告位置列表
      end_time: "", //结束时间
      // storeid: "", //门店id
      businessList: [{}], //商户列表
      business_id: "", //选中的商户ID
      storeList: [{}], //门店列表
      storeid: "", //选中的门店ID

      admin_type: "",
      coupon_name: "", //活动名称搜索的input框的值
      active_status: "", //选择活动状态
      merchant_status: "", //选择商户
      merchantShow: false, //商户个别权限的隐藏
      storeShow: false, //门店权限的隐藏
      show: false,
      selectGoodsListShow: false, //指定商品的显示隐藏
      goodsListShow: false,
      cur_page: 0, //设置一个默认值
      addTableData: [], //优惠券商品列表
      goodsListTotal: 10, //优惠商品总条数
      tableData: [], //表格渲染数据
      coupon_id: "", //编辑时的优惠券id
      activeListTotal: 100, //表格总条数
      searchIpt: "", //优惠商品列表搜索值
      dataSelections: "", //选中的商品列表数据
      selectTableData: [], //指定商品的商品数据
      goods_type: "", //指定商品还是全部商品类型
      //总页数
      currentPage: 1,
      pageSize: 10,
      value2: true,

      //添加时的数据列表
      ruleForm: {
        businessList: [{}], //商户列表
        business_id: "", //选中的商户ID
        storeList: [{}], //门店列表
        storeid: "", //选中的门店ID
        company_name: "", //		公司名称
        send_describe: "", //配送描述
        is_open: "", //是否启用
        logistics_id: "", //物流公司id
      },
      //添加时的表单验证数据
      ruleValidate: {
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        send_describe: [
          { required: true, message: "请输入配送描述", trigger: "blur" },
        ],
        // business_id: [
        //   { required: true, message: "请选择商户", trigger: "change" },
        // ],
        // store_name: [{ required: true, message: "请选择门店", trigger: "change" },],
      },
    };
  },
  methods: {
    //刷新页面按钮
    refresh() {
      location.reload();
    },

    //关闭添加商品窗口
    closeAdd() {
      this.goodsListShow = false;
      this.selectGoodsListShow = false;
    },

    //选中商户查询门店
    changeMerchant(business_id) {
      console.log(business_id);
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          console.log(res);
          this.storeList = res.data;
          this.ruleForm.storeList = res.data;
        });
    },

    //添加物流公司按钮(弹出添加物流公司模态框)
    add() {
      this.changeShow = true;
    },

    //添加物流公司时候的提交按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //物流添加接口
          this.$request
            .setLogistics({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              store_id: this.ruleForm.storeid,
              logistics_id: "",
              company_name: this.ruleForm.company_name,
              send_describe: this.ruleForm.send_describe,
              is_open: this.ruleForm.is_open,
            })
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                console.log(res);
                this.ruleForm = "";
                this.changeShow = false;
                // this.tableData = res.data.data;
                // this.activeListTotal = res.data.count;
              } else {
                alert("请求失败");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //物流公司列表查询按钮
    screen() {
      this.$request
        .logisticsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          store_id: this.storeid,
          company_name: this.rcompany_name, //物流公司搜索
          is_open: this.is_open,
          limit: 10,
          page: 1,
        })
        .then((res) => {
          console.log(res);
          this.changeShow = false;
          this.allData = true;
          this.tableData = res.data;
          // this.activeListTotal = res.data.count;
        });
    },

    //选择每页多少条并从新请求列表
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = parseInt(`${val}`);
      this.$request
        .logisticsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          store_id: this.storeid,
          company_name: this.rcompany_name, //物流公司搜索
          is_open: this.is_open,
          limit: 10,
          page: 1,
        })
        .then((res) => {
          console.log(res);
          this.changeShow = false;
          this.tableData = res.data;
          // this.activeListTotal = res.data.count;
        });
    },

    //跳转页码
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = parseInt(`${val}`);
      console.log(1111111111111111111, this.currentPage);
      this.$request
        .logisticsList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          store_id: this.storeid,
          company_name: this.rcompany_name, //物流公司搜索
          is_open: this.is_open,
          limit: 10,
          page: 1,
        })
        .then((res) => {
          console.log(res);
          this.changeShow = false;
          this.tableData = res.data;
          // this.activeListTotal = res.data.count;
        });
    },

    //修改上下架
    switchChange(is_open, logistics_id, store_id) {
      console.log(is_open, logistics_id, store_id);
      this.$request
        .setLogistics({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          store_id: store_id,
          logistics_id: logistics_id,

          is_open: is_open,
        })
        .then((res) => {
          console.log(res);
          this.$request
            .logisticsList({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              store_id: this.storeid,
              company_name: this.rcompany_name, //物流公司搜索
              is_open: this.is_open,
              limit: 10,
              page: 1,
            })
            .then((res) => {
              console.log(res);
              // this.changeShow = false;
              this.tableData = res.data;
              // this.activeListTotal = res.data.count;
            });
        });
    },

    //编辑商品按钮
    edit(store_id, company_name, send_describe, is_open, logistics_id) {
      console.log(
        "编辑商品的默认值",
        store_id,
        company_name,
        send_describe,
        is_open,
        logistics_id
      );
      this.changeShow = true;
      this.ruleForm.storeid = store_id;
      this.ruleForm.company_name = company_name;
      this.ruleForm.send_describe = send_describe;
      this.ruleForm.is_open = is_open.toString();
      this.$request
      .getUpStoreData({
        token: this.$storage.getLocal("token"),
        storeid: store_id,
      })
      .then((r) => {
        console.log('商户id', r);
        if (r.code == 0) {
          //   console.log(r);
          this.ruleForm.business_id = r.data.pstoreid;
        
        }
      });
    },

    //删除广告
    deleteAdver(logistics_id) {
      console.log(logistics_id);
      this.$request
        .deleteLogistics({
          token: this.$storage.getLocal("token"),
          logistics_id: logistics_id,
        })
        .then((res) => {
          console.log(res);
          this.$request
            .logisticsList({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              store_id: this.storeid,
              company_name: this.rcompany_name, //物流公司搜索
              is_open: this.is_open,
              limit: 10,
              page: 1,
            })
            .then((res) => {
              console.log(res);
              this.changeShow = false;
              this.tableData = res.data;
              // this.activeListTotal = res.data.count;
            });
        });
    },
  },

  created() {
    //请求商户列表接口
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.businessList = res.data;
      this.ruleForm.businessList = res.data;
    });
    //不同权限下的商户显示
    this.admin_type = this.$storage.getLocal("type");
    console.log("business_id", this.business_id);
    if (this.admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (this.admin_type == 2) {
      this.storeShow = true;
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          // business_id: business_id,
        })
        .then((res) => {
          console.log("帅气的我",res);
          this.ruleForm.storeList = res.data;
            this.storeList = res.data;
        });
    }
  },
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 32%;
  width: 700px;
  height: 500px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 700px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
</style>